export const msalConfig = {
    auth: {
        clientId: '8673c758-7a0d-40e0-9136-fced78784ee8',
        authority: 'https://boomtools.b2clogin.com/boomtools.onmicrosoft.com/B2C_1_AccessFlow/',
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        knownAuthorities: ['boomtools.b2clogin.com'],
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true, 
    },
    system: {
        tokenRenewalOffsetSeconds: 300,
    }
};

export const tokenRequest = {
    scopes: [
        "https://boomtools.onmicrosoft.com/2b6a378a-a110-4835-a156-fa887083f7db/user.write",
        "https://boomtools.onmicrosoft.com/2b6a378a-a110-4835-a156-fa887083f7db/user.read"
    ]
};
