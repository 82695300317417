import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { AuthProvider } from './AuthContext';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from './msalInstance';

createRoot(document.getElementById('root')).render(
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <AuthProvider>
                <App />
            </AuthProvider>
        </Provider>
    </MsalProvider>
);

reportWebVitals();
