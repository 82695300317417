import React, { Suspense, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './scss/style.scss';
import WalletProvider from './components/WalletProvider';
import AuthContext from './AuthContext';
import routes from './routes';
import DefaultLayout from './layout/DefaultLayout';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

const App = () => {
    return (
        <WalletProvider>
            <Router>
                <Suspense fallback={loading}>
                    <DefaultLayout routes={routes} />
                </Suspense>
            </Router>
        </WalletProvider>
    );
};

export default App;
