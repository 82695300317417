import React, { useContext } from 'react';
import msalInstance from './msalInstance';

const AuthContext = React.createContext();

// AuthContext.js

const isAuthorized = (user, allowedRoles) => {
    if (!Array.isArray(allowedRoles)) {
        //console.log('Logging for isAuthorized function:');
        //console.log('User:', JSON.stringify(user, null, 2));
        //console.log('AllowedRoles:', JSON.stringify(allowedRoles, null, 2));
        //console.error('allowedRoles should be an array:', allowedRoles);
        return false;
    }
    if (!user || !user.role) return false;
    return allowedRoles.includes(user.role);
}

const AuthProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const checkCurrentSession = () => {
        const accounts = msalInstance.getAllAccounts();
        if (accounts && accounts.length > 0) {
            const response = accounts[0];
            const role = response.idTokenClaims.extension_UserRole;
            const username = response.idTokenClaims.extension_Username;
            setUser({ ...response, role, username });
        }
    };

    // This effect only runs once when the component mounts to set initial user state
    React.useEffect(() => {
        checkCurrentSession();
        setLoading(false);  // assuming this is the only place we're using it
    }, []);

    // This effect handles redirects; it only runs when there's an actual redirect
    React.useEffect(() => {
        msalInstance.handleRedirectPromise()
            .then(response => {
                if (response !== null) {
                    /*console.log('Redirect handled in AuthProvider', response);*/
                    const role = response.idTokenClaims.extension_UserRole;
                    const username = response.idTokenClaims.extension_Username;
                    setUser({ ...response.account, role, username });
                }
            })
            .catch(error => {
                console.error('Failed to handle redirect in AuthProvider', error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loginWithPopup = async () => {
        try {
            const response = await msalInstance.loginPopup();
            const role = response.idTokenClaims.extension_UserRole;
            const username = response.idTokenClaims.extension_Username;
            setUser({ ...response.account, role, username });
        } catch (error) {
            console.error('Failed to login with popup', error);
        }
    };

    const loginWithRedirect = () => {
        msalInstance.loginRedirect();
    };

    const logout = () => {
        msalInstance.logout();
        setUser(null);
    };

    const isAuthenticatedFunc = () => {
        return user !== null;
    };

    const isAdmin = () => {
        return user && user.role === 'Admin';
    };

    const isBeta = () => {
        return user && user.role === 'Beta';
    };

    return (
        <AuthContext.Provider value={{
            msalInstance,
            user,
            setUser,
            loading,
            loginWithPopup,
            loginWithRedirect,
            logout,
            isAuthenticated: isAuthenticatedFunc,
            isAuthorized,
            isAdmin,
            isBeta
        }}>
            {children}
        </AuthContext.Provider>
    );
};

// Convenience hook to use AuthContext
const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
}

export default AuthContext;
export { AuthProvider, useAuth };
