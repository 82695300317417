import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import usePrivateRoute from './hooks/usePrivateRoute';

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard').then(module => ({ default: module.default })))

// My Content
const Home = React.lazy(() => import('./components/Home').then(module => ({ default: module.default })))
const Disclaimer = React.lazy(() => import('./components/Disclaimer').then(module => ({ default: module.default })))
const HunterExplorer = React.lazy(() => import('./features/hunters/hunterExplorer/HunterExplorer').then(module => ({ default: module.default })))
const HunterBaseStatRanges = React.lazy(() => import('./features/hunters/HunterBaseStatRanges').then(module => ({ default: module.default })))
const HunterCurrentStatRanges = React.lazy(() => import('./features/hunters/HunterCurrentStatRanges').then(module => ({ default: module.default })))
const HunterTypesRarities = React.lazy(() => import('./features/hunters/HunterTypesRarities').then(module => ({ default: module.default })))
const HunterProfile = React.lazy(() => import('./features/hunters/HunterProfile').then(module => ({ default: module.default })))
const Callback = React.lazy(() => import('./views/Callback').then(module => ({ default: module.default })))
const Login = React.lazy(() => import('./views/pages/login/Login').then(module => ({ default: module.default })))
const Register = React.lazy(() => import('./views/pages/register/Register').then(module => ({ default: module.default })))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404').then(module => ({ default: module.default })))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500').then(module => ({ default: module.default })))
const UserAdmin = React.lazy(() => import('./components/UserAdmin').then(module => ({ default: module.default })))
const UserSettings = React.lazy(() => import('./components/UserSettings').then(module => ({default: module.default })))
const HunterListings = React.lazy(() => import('./features/hunters/HunterListings').then(module => ({ default: module.default })))
const MarketFilterEditor = React.lazy(() => import('./components/settings/MarketFilterEditor').then(module => ({ default: module.default })))
const SummonHunterSimulator = React.lazy(() => import('./features/hunters/HunterSummoningSimulator').then(module => ({ default: module.default })))

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, loading } = usePrivateRoute();

    if (loading) {
        return <div>Loading...</div>; // Replace with a loading spinner or similar
    }
    return isAuthenticated ? children : <Navigate to="/login" />;
}

const routes = [
    { path: '/disclaimer', name: 'Disclaimer', element: <Disclaimer /> },
    { path: '/hunterExplorer', name: 'Hunter Explorer', element: <HunterExplorer /> },
    { path: '/hunterBaseStatRanges', name: 'Hunter Base Stat Ranges', element: <HunterBaseStatRanges /> },
    { path: '/hunterCurrentStatRanges', name: 'Hunter Current Stat Ranges', element: <HunterCurrentStatRanges /> },
    { path: '/hunterTypesRarities', name: 'Hunter Types & Rarities', element: <HunterTypesRarities /> },
    { path: '/hunterProfile', name: 'Hunter Profile Input', element: <HunterProfile /> },
    { path: '/hunterProfile/:id', name: 'Hunter Profile', element: <HunterProfile /> },
    { path: '/login', name: 'Login Page', element: <Login /> },
    { path: '/register', name: 'Register Page', element: <Register /> },
    { path: '/404', name: 'Page 404', element: <Page404 /> },
    { path: '/500', name: 'Page 500', element: <Page500 /> },
    { path: '/callback', name: 'Callback', element: <Callback /> },
    { path: '/dashboard', name: 'Dashboard', element: <PrivateRoute><Dashboard /></PrivateRoute> },
    { path: '/userAdmin', name: 'User Admin', element: <PrivateRoute><UserAdmin /></PrivateRoute> },
    { path: '/userSettings/:tab?', name: 'User Settings', element: <PrivateRoute><UserSettings /></PrivateRoute> },
    { path: '/hunterListings', name: 'Hunter Listings', element: <PrivateRoute><HunterListings /></PrivateRoute> },
    { path: '/marketFilterEditor', name: 'Market Filter Editor', element: <PrivateRoute><MarketFilterEditor /></PrivateRoute> },
    { path: '/summonHunterSimulator', name: 'Summon Hunter Simulator', element: <SummonHunterSimulator />},
    { path: '*', name: 'Home', element: <Home /> },
];

export default routes;