// WalletProvider.js
import React, { useState, useEffect } from 'react';
import WalletContext from './WalletContext';

const WalletProvider = ({ children }) => {
    const [walletAddress, setWalletAddress] = useState(localStorage.getItem('walletAddress') || '');
    const [filterByWallet, setFilterByWallet] = useState(JSON.parse(localStorage.getItem('filterByWallet') || 'false'));

    useEffect(() => {
        localStorage.setItem('walletAddress', walletAddress);
    }, [walletAddress]);

    useEffect(() => {
        localStorage.setItem('filterByWallet', JSON.stringify(filterByWallet));
    }, [filterByWallet]);


    //const [walletAddress, setWalletAddress] = useState('');
    //const [filterByWallet, setFilterByWallet] = useState(false);

    return (
        <WalletContext.Provider value={{ walletAddress, setWalletAddress, filterByWallet, setFilterByWallet }}>
            {children}
        </WalletContext.Provider>
    );
};

export default WalletProvider;
