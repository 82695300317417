// usePrivateRoute.js
import { useContext } from 'react';
import AuthContext from '../AuthContext';

const usePrivateRoute = () => {
    const { isAuthenticated, loading } = useContext(AuthContext);
    /*console.log("isAuthenticated inside usePrivateRoute:", isAuthenticated);*/
    return { isAuthenticated, loading };  // Return both values
}

export default usePrivateRoute;
