import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CContainer, CHeader, CHeaderBrand, CHeaderDivider, CHeaderNav, CHeaderToggler, CNavLink, CNavItem, CFormCheck, CButton} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilMenu } from '@coreui/icons';
import { AppBreadcrumb } from './index';
import { AppHeaderDropdown } from './header/index';
import logo from '../assets/brand/boomTools-logo.png';
import WalletContext from './WalletContext';
import AuthContext from '../AuthContext';

const AppHeader = () => {
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state) => state.sidebarShow);
    const { walletAddress, setWalletAddress, filterByWallet, setFilterByWallet } = useContext(WalletContext);
    const { user, loginWithPopup, logout } = useContext(AuthContext);

    return (
        <CHeader position="sticky" className="mb-4">
            <CContainer fluid>
                <CHeaderToggler className="ps-1" onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}>
                    <CIcon icon={cilMenu} size="lg" />
                </CHeaderToggler>
                <CHeaderBrand className="mx-auto d-md-none" to="/">
                    <img src={logo} height="48" alt="Logo-m" />
                </CHeaderBrand>
                <CHeaderNav className="d-none d-md-flex me-auto">
                    <CNavItem>
                        <CNavLink to="/home" component={NavLink}>Home</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink to="/disclaimer" component={NavLink}>Disclaimer</CNavLink>
                    </CNavItem>
                </CHeaderNav>
                <CHeaderNav className="ms-auto d-flex justify-content-between" style={{ flex: 1 }}>
                    <div className="d-flex justify-content-center" style={{ flex: 1 }}>
                        <AppHeaderDropdown />
                    </div>
                    <div>
                        {user ? (
                            <>
                                <span>Welcome, {user.username} | </span>
                                <span>
                                    <NavLink to="/userSettings">
                                        <CButton color="primary" style={{ padding: '1px 5px' }}>Settings</CButton>
                                    </NavLink> |
                                </span>
                                <CButton color="primary" style={{ padding: '1px 5px' }} onClick={logout}>Logout</CButton>
                            </>
                        ) : (
                            <CButton color="primary" style={{ padding: '1px 5px' }} onClick={loginWithPopup}>Login</CButton>
                        )}
                    </div>
                </CHeaderNav>
            </CContainer>
            <CHeaderDivider />
            <CContainer fluid>
                <AppBreadcrumb />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontWeight: 'bold' }}>
                    <style>
                        {`#btn-check-outlined + label {padding: 5px 10px;}`}
                    </style>
                    <label style={{ marginRight: '5px' }}>
                        <CFormCheck
                            button={{ color: 'secondary', variant: 'outline' }}
                            id="btn-check-outlined"
                            autoComplete="off"
                            label="My Hunters"
                            type="checkbox"
                            checked={filterByWallet}
                            onChange={(e) => setFilterByWallet(e.target.checked)}
                        />
                    </label>
                    <input
                        type="text"
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                        placeholder="Enter Wallet Address"
                        style={{ width: '400px' }}
                    />
                </div>
            </CContainer>
        </CHeader>
    )
};

export default AppHeader;
