import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
  cilHome,
  cilGrid,
  cilSearch,
    cilUser,
    cilCog,
    cilCart,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Home',
    to: '/',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Hunters',
  },
  {
    component: CNavItem, 
    name: 'Hunter Explorer',
    to: '/hunterExplorer',
    icon: <CIcon icon={cilSearch} customClassName="nav-icon" />,
  },
  {
      component: CNavItem,
      name: 'Hunter Profile',
      to: '/hunterProfile',
      icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
      component: CNavGroup,
      name: 'Reports',
      to: '/reports',
      icon: <CIcon icon={cilGrid} customClassName="nav-icon" />,
      items: [
          {
              component: CNavItem,
              name: 'Hunter Base Stat Ranges',
              to: '/hunterBaseStatRanges',
          },
          {
              component: CNavItem,
              name: 'Hunter Current Stat Ranges',
              to: '/hunterCurrentStatRanges',
          },
          {
              component: CNavItem,
              name: 'Hunter Types and Rarities',
              to: '/hunterTypesRarities',
          },
      ],
    },
    {
        component: CNavGroup,
        name: 'Market',
        to: '/market',
        icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
        items: [
            {
                component: CNavItem,
                name: 'Hunter Listings',
                to: '/hunterListings',
            }
        ]
    },
    {
        component: CNavGroup,
        name: 'Admin',
        to: '/admin',
        icon: <CIcon icon={cilCog} customClassName="nav-icon" />,
        items: [
            {
                component: CNavItem,
                name: 'User Admin',
                to: '/userAdmin',
            }
        ]
    },
]

export default _nav
